<template>
  <main class="content container">
        <!--pay-->
        <article class="pay">
            <h1 class="page-title text-center">Bestellübersicht</h1>
          <Summary />
            <div class="form-check mb-4">
                <input class="form-check-input" type="checkbox" name="rules" id="rules" v-model="rules">
                <label class="form-check-label" for="rules">
                    Ich habe die allgemeine Geschäftsbedingungen, die Erklärung zum Datenschutz sowie die Liefer- und Zahlungsbedinungen gelesen
                    <a href="ttps://www.loewe-zaun.de/agb/" target="_blank" class="link">[Unsere AGB`s]</a>
                </label>
            </div>

            <div class="row align-items-center">
                <div class="w-auto mb-2">
                    <router-link :to="{ name: 'checkout.customer' }" class="btn btn-outline-primary min-width-170">Zurück</router-link>
                </div>
                <div class="w-auto mb-2">
                  <button v-if="!status" @click="onClickOrder" type="button" :class="[!rules ? 'disabled': '']" class="btn btn-primary min-width-170">Kostenpflichtig Bestellen</button>
                </div>
                <div class="w-auto mb-2">
                  <router-link :to="{ name: 'cart' }" class="link small-size-14">Bestellübersicht</router-link>
                </div>
            </div>
        </article>
        <InfoBox />
    </main>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import InfoBox from './cart/infoBox'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Summary from '@/pages/checkout/payment/summary'
export default {
  components: {
    InfoBox,
    Summary
  },
  setup () {
    useMeta({
      title: 'Bestellübersicht'
    })
    const store = useStore()
    const router = useRouter()
    // mounted
    onMounted(() => {
      // check fields if != ok redirect back
      nextStep()
    })
    const rules = ref(false)
    const status = ref(false)
    // computed
    const paymentType = computed(() => {
      return store.getters['checkout/paymentType']
    })
    // actions
    function nextStep () {
      // checking fields
      const errors = []
      const address = store.getters['checkout/customerAddress']
      for (const key in address) {
        if (key !== 'company') {
          if (address[key] === '') {
            errors.push(key)
          }
        }
      }
      if (errors.length > 0) {
        router.push({ name: 'checkout.customer' })
      }
    }
    function onClickOrder () {
      status.value = true
      if (rules.value) {
        const order = store.dispatch('checkout/order')
        order.then(() => {
          switch (paymentType.value.label) {
            case 'Paypal':
              // router to paypal checkout
              router.push({ name: 'checkout.total' })
              break
            case 'Mollie':
              // router to Mollie checkout
              router.push({ name: 'checkout.total' })
              break
            default:
              router.push({ name: 'checkout.success' })
          }
        })
      }
    }
    return {
      rules,
      status,
      onClickOrder
    }
  }
}
</script>
